import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.07 14.991c4.13 0 7.518-3.378 7.518-7.495C19.588 3.378 16.2 0 12.071 0 7.94 0 4.659 3.378 4.659 7.496c0 4.117 3.282 7.495 7.412 7.495zM60.035 14.991c4.13 0 7.518-3.378 7.518-7.495C67.553 3.378 64.165 0 60.035 0s-7.517 3.378-7.517 7.496c0 4.117 3.388 7.495 7.517 7.495zM60.035 17.947L36 42.017l-24.035-24.07L0 29.983V72h6.035V53.947H18V72h6.035V38.956l5.93 6.018h12.07l5.93-6.018V72H54V53.947h11.965V72H72V29.983L60.035 17.947z"
        fill="#66B0E9"
      />
    </svg>
  )
}

export default SvgComponent
