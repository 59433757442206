import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../layouts"
import Hero from "../components/Hero"
import LetterAnimation from "../animations/LetterAnimation"
import { PageContainer } from "../styles/global"
import { remCalc, pageMargins } from "../styles/utils"
import { typography, color } from "../styles/variables"
import NextPage from "../components/NextPage"
import CircleAndText from "../components/CircleAndText"
import List from "../components/List"
import Item from "../components/List/Item"
import Video from "../components/Video"
import Line from "../components/Line"
import Arrow from "../images/svgs/Explore"
import StyledStats from "../styles/StyledStats"
import House from "../images/svgs/House"
import Bag from "../images/svgs/Bag"
import People from "../images/svgs/People"
import { respondTo } from "../styles/respondTo"
import useIsTablet from "../hooks/useIsTablet"
import animationData from "../animations/DESKTOP_MOBILE/04-UKOverview.json"
import mobileAnimationData from '../animations/DESKTOP_MOBILE/04-UKOverview_MOBILE.json'

const StyledOverview = styled.div`
  & .overview_header-sub {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3rem 0;
    & .overview-stats {
      flex-wrap: wrap;
    }
    ${pageMargins};
    @media only screen and (max-width: 1024px) {
      align-items: center;
      flex-direction: column;
      padding: 2rem 0 0 0;
      ${pageMargins};
    }

    & .overview_header {
      font-size: ${remCalc(40)};
      line-height: ${remCalc(56)};
      font-family: ${typography.type.primary};

      width: 40%;
      padding-right: 2rem;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-right: 0;
        font-size: 32px;
        line-height: 44px;
      }
    }
    & .overview_sub {
      width: 100%;
      @media only screen and (min-width: 1024px) {
        width: 60%;
      }
    }
    & p {
      font-size: ${remCalc(24)};
      line-height: ${remCalc(34)};
      font-family: ${typography.type.primary};
      padding-top: 0.5rem;
      @media only screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  & .overview_title {
    font-size: 2.5rem;
    line-height: 3.2rem;
    text-align: center;
    width: 35rem;
    margin: 3rem auto 0 auto;
    font-family: ${typography.type.primary};
    @media only screen and (max-width: 640px) {
      font-size: 2rem;
      line-height: 44.6px;
    }
  }
`

const NationalOverview = ({ location }) => {
  const isTablet = useIsTablet()
  const data = useStaticQuery(graphql`
    query {
      construction: file(relativePath: { eq: "uk-overview-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgImage: file(relativePath: { eq: "uk-overview-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vidPoster: file(relativePath: { eq: "overview-vid-poster.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      builder: file(relativePath: { eq: "uk-overview-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      man: file(relativePath: { eq: "uk-overview-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      solar: file(relativePath: { eq: "uk-overview-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        bgImage={data.bgImage}
        mobileColors={color.header.uk_overview}
        location={location.pathname}
        headerColor="#C04B52"
      >
        {isTablet ? (
          <h1 className="hero_title">Landsec contribution overview</h1>
        ) : (
          <LetterAnimation duration={0.7} delay={0.017} initDelay={0.1}>
            <h1
              data-scroll
              data-scroll-speed="2"
              className="hero_title text-animated"
            >
              <span className="line-inner">Landsec</span>
            </h1>
            <h1
              data-scroll
              data-scroll-speed="2"
              className="hero_title text-animated"
            >
              <span className="line-inner">contribution</span>
            </h1>
            <h1
              data-scroll
              data-scroll-speed="2"
              className="hero_title text-animated"
            >
              <span className="line-inner">overview</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>
      <StyledOverview>
        <PageContainer>
          <CircleAndText image={data.construction}>
            <div
              data-scroll
              data-scroll-speed="1"
              className="circle-text_container first-section"
            >
              <p>As one of the UK’s largest listed property companies, with destinations spanning the width and breadth of the country, Landsec makes a significant contribution to the economic prosperity of the UK and works to tackle some of the toughest issues facing society today – including the threat of climate change and the social inequalities that disproportionately affect those further from the job market.</p>
              <List device="desktop">
                <Item scrollElementClass="headline-stats">
                  <p>Headline statistics</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="social-value">
                  <p>Social value</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="climate-change">
                  <p>Climate change</p>
                  <Arrow />
                </Item>
              </List>
            </div>
            <List device="tablet">
              <Item scrollElementClass="headline-stats">
                <p>Headline statistics</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="social-value">
                <p>Social value</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="climate-change">
                <p>Climate change</p>
                <Arrow />
              </Item>
            </List>
            <Line />
          </CircleAndText>
          <div className="overview_header-sub">
            <div className="overview_header">
              <h2>We don’t just build and rent out buildings.</h2>
            </div>
            <div className="overview_sub">
              <p>We create destinations for businesses of all sizes – from start ups to international conglomerates – to inspire workforces, increase productivity and drive optimal performance.</p>
            </div>
          </div>
          <StyledStats
            align="flex-start"
            className="headline-stats overview-stats p-bottom-0"
          >
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <House />
                <h3 style={{ color: "#C04B52" }}>£4.4bn</h3>
              </div>
              <div className="stat_para">
                <p>Landsec's ten-year contribution to the economy through property development.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <People />
                <h3 style={{ color: "#66B0E9" }}>£11.7bn</h3>
              </div>
              <div className="stat_para">
                <p>Total contribution to the UK economy each year from people based at our assets.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <Bag />
                <h3 style={{ color: "#8E877B" }}>£7.1bn</h3>
              </div>
              <div className="stat_para">
                <p>Facilitated GDP contribution across our estate.</p>
              </div>
            </div>
          </StyledStats>
          <StyledStats className="single p-top-0">
            <div className="stat_item item-circle_image">
              <Img fluid={data.builder.childImageSharp.fluid} />
            </div>
            <div className="stat_item large-para">
              <p>
                We work responsibly with suppliers, asking them to adhere to our{" "}
                code of conduct to drive best practice across workplace safety
                and wellbeing.
              </p>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 style={{ color: "#66B0E9" }}>£110m</h3>
              </div>
              <div className="stat_para">
                <p>The GDP contribution of our supply chain per annum.</p>
              </div>
            </div>
            <Line />
          </StyledStats>

          <StyledStats className="social-value overview-stats">
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 style={{ color: "#C04B52" }}>£6.5m</h3>
              </div>
              <div className="stat_para">
                <p>In 2020/21, we delivered over £6.5m of social value through our social sustainability programmes.</p>
              </div>
            </div>

            <div className="stat_item large-para">
              <p>This was part of our commitment to deliver £25m by 2025.</p>
            </div>
            <div className="stat_item">
              <Img fluid={data.man.childImageSharp.fluid} />
            </div>
            <Line />
          </StyledStats>
          <h2 className="overview_title m-w-100">We’re leaders in our sector in the fight against climate change</h2>
          <StyledStats className="climate-change p-bottom-0 overview-stats align-flex-start">
            <div className="stat_item m-w-100">
              <div className="stat_para t-w-100">
                <p
                  style={{
                    color: "#8E877B",
                    fontSize: "2rem",
                    lineHeight: "2.75rem",
                  }}
                >We’ve committed to becoming a net zero carbon business by 2030.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 style={{ color: "#8E877B", fontSize: "3rem" }}>55%</h3>
              </div>
              <div className="stat_para">
                <p>of our carbon emissions have been reduced compared to 2013/14.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 style={{ color: "#8E877B", fontSize: "3rem" }}>43%</h3>
              </div>
              <div className="stat_para">
                <p>reduction in our energy intensity compared to 2013/14.</p>
              </div>
            </div>
          </StyledStats>
          <StyledStats className="m-top-0 overview-stats">
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 style={{ color: "#8E877B", fontSize: "3rem" }}>100%</h3>
              </div>
              <div className="stat_para">
                <p>We continue to procure 100% renewable electricity across our portfolio.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 style={{ color: "#8E877B", fontSize: "3rem" }}>100%</h3>
              </div>
              <div className="stat_para">
                <p>We continue to divert 100% of waste from landfill across our operational activities.</p>
              </div>
            </div>
            <div className="stat_item">
              <Img fluid={data.solar.childImageSharp.fluid} />
            </div>
            <Line />
          </StyledStats>
          <Video
            poster={data.vidPoster.childImageSharp.fluid}
            url="https://www.youtube.com/watch?v=-1tt4r976Tk&feature=emb_logo"
          />
          <NextPage
            page={{
              title: "Total economic contribution",
              path: "economic-contribution",
            }}
          />
        </PageContainer>
      </StyledOverview>
    </Layout>
  )
}

export default NationalOverview
