import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={73}
      height={61}
      viewBox="0 0 73 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.978 11.982H28.11V6.08h17.956v5.903h-.088zm6.337 0h-.264v-.352V.44 0H22.037V12.07H1.352c-.176 0-.264 0-.44.089v47.93h72V11.981H52.315z"
        fill="#8E877B"
      />
    </svg>
  )
}

export default SvgComponent
