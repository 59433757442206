import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={72}
      height={60}
      viewBox="0 0 72 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72 35.982L36 0 0 35.982h11.97V60h18.045V41.965h11.97V60H60.03V35.982H72z"
        fill="#C04B52"
      />
    </svg>
  )
}

export default SvgComponent
